

















































































import Vue from "vue";
import { mapGetters } from "vuex";
import { Bankstatement, Loan } from "@/types";
import { apiAxios } from "@/store/apiAxios";

export default Vue.extend({
  name: "ExpirationList",
  props: { loanId: { type: Number, required: true } },
  data() {
    return {
      isLoading: false,
    };
  },
  watch: {
    "loan.bankstatement": {
      handler(val: Bankstatement[]) {
        if (val.length > 0) {
          this.emptyBankstatement = false;
        } else {
          this.emptyBankstatement = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async renewConsent(requisitionId: string, userId: number, bankId: string) {
      const response = await apiAxios.get(
        `api/bankstatement/renew/${requisitionId}/${userId}/${bankId}/`
      );

      this.isLoading = false;
      const a = document.createElement("a");
      a.href = response.data;
      a.target = "_blank";
      document.appendChild(a);
      a.click();
      document.removeChild(a);
      window.location.href = response.data;
    },
    setFallbackImage(event: Event) {
      (event.target as HTMLImageElement).src = "";
    },
    getDayLeft(bankstatement: Bankstatement) {
      const day = 1000 * 60 * 60 * 24;
      const today = new Date().getTime();
      const dayToDiff = new Date(bankstatement.consent_expiration).getTime();
      const differenceMs = Math.abs(dayToDiff - today);
      return Math.round(differenceMs / day);
    },
  },
  computed: {
    ...mapGetters({
      loans: "loans/getLoans",
      accounts: "account/getAccount",
    }),
    loan(): Loan | undefined {
      const loans = this.loans as Loan[];
      const item = loans.filter((l: Loan) => l.ac_deal_id === this.loanId)[0];
      return item;
    },
    sortedBankStatements(): Bankstatement[] {
      const loan = this.loan;
      if (!loan) {
        return [];
      }

      if (loan.bankstatement.length > 1) {
        return loan.bankstatement
          .slice()
          .sort((a: Bankstatement, b: Bankstatement) => {
            return this.getDayLeft(a) - this.getDayLeft(b);
          });
      } else {
        return loan.bankstatement;
      }
    },
    emptyBankstatement: {
      get(): boolean {
        return this.$store.getters["bankstatement/getEmptyBankstatement"];
      },

      set(value: boolean) {
        this.$store.commit("bankstatement/setEmptyBankstatement", value);
      },
    },
  },
});
