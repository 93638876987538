



























































import Vue from "vue";
import { mapGetters } from "vuex";
import { Loan } from "@/types";
import { apiAxios } from "@/store/apiAxios";

export default Vue.extend({
  name: "ExpirationList",
  props: { loanId: { type: Number, required: true } },
  data: () => ({
    isLoading: false,
    bankSelected: null,
    nameBankSelected: "",
    userId: 1,
    intervalId: null as null | number,
  }),
  methods: {
    // we need loanId and bankId
    async newConsent() {
      const result = await apiAxios.get(
        `api/bankstatement/new/${this.loanId}/${this.bankSelected}/`
      );
      const a = document.createElement("a");
      a.href = result.data.consent_link;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      this.pollForChanges(result.data.id);
      this.isLoading = false;
    },

    pollForChanges(bankstatementId: string) {
      this.intervalId = window.setInterval(async () => {
        const response = await apiAxios.get(
          `api/bankstatement/requisition-status/${bankstatementId}/`
        );
        if (response.data.is_linked || response.data.is_error) {
          this.$store.dispatch("loans/fetchLoans");

          if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
          }
        }
      }, 5000);
    },
  },
  computed: {
    ...mapGetters({
      loans: "loans/getLoans",
      banks: "bankstatement/getBanks",
    }),
    loan(): Loan {
      return this.loans.filter((l: Loan) => l.ac_deal_id === this.loanId)[0];
    },
  },

  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
});
